@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes screw {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.root {
  position: absolute;
  width: 48px;
  height: 48px;
  left: -8px;
  top: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCore {
  min-width: 33px;
  width: 33px;
  position: absolute;
  animation: rotate 10s infinite linear;
  animation-play-state: paused;
}

.centerCore {
  min-width: 40px;
  width: 40px;
  position: absolute;
  animation: rotate 10s infinite reverse linear;
  animation-play-state: paused;
}

.outerCore {
  position: absolute;
  animation: screw 6s infinite ease-in-out;
  animation-play-state: paused;
}

.animPlay {
  animation-play-state: running !important;
}
